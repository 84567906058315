import {
  SET_MEMES,
  LOADING_DATA,
  LIKE_MEME,
  UNLIKE_MEME,
  DISLIKE_MEME,
  UNDISLIKE_MEME,
  DELETE_MEME,
  SET_ERRORS,
  POST_MEME,
  CLEAR_ERRORS,
  LOADING_UI,
  SET_MEME,
  STOP_LOADING_UI,
  SUBMIT_COMMENT,
  SET_RANDOM_MEME,
  GET_USER_PROFILE_DATA
} from '../types';
import axios from 'axios';

const handleMemeReaction = (memeId, actionType) => async (dispatch) => {
  try {
    const action = actionType.split("_")
    const action2 = action[0].toLowerCase()
    const endpoint = `/meme/${memeId}/${action2}`;
    const res = await axios.get(endpoint);

    dispatch({
      type: actionType,
      payload: res.data
    });
  } catch (err) {
    console.log(err);
  }
};

// Get all memes
export const getMemes = () => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .get('/memes')
    .then((res) => {
      dispatch({
        type: SET_MEMES,
        payload: res.data
      });
      dispatch({ type: STOP_LOADING_UI });
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data
      });
    });
};
export const getMeme = (memeId) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .get(`/meme/${memeId}`)
    .then((res) => {
      dispatch({
        type: SET_MEME,
        payload: res.data
      });
      dispatch({ type: STOP_LOADING_UI });
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data
      });
    });
};
// Post a meme
export const postMeme = (newMeme) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .post('/meme', newMeme)
    .then((res) => {
      dispatch({
        type: POST_MEME,
        payload: res.data
      });
      dispatch(clearErrors());
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data
      });
    });
};
// Like a meme
export const likeMeme = (memeId) => handleMemeReaction(memeId, LIKE_MEME);
// Unlike a meme
export const unlikeMeme = (memeId) => handleMemeReaction(memeId, UNLIKE_MEME);
// Dislike a meme
export const dislikeMeme = (memeId) => handleMemeReaction(memeId, DISLIKE_MEME);
// Undislike a meme
export const undislikeMeme = (memeId) => handleMemeReaction(memeId, UNDISLIKE_MEME);
// Submit a comment
export const submitComment = (memeId, commentData) => (dispatch) => {
  axios
    .post(`/meme/${memeId}/comment`, commentData)
    .then((res) => {
      dispatch({
        type: SUBMIT_COMMENT,
        payload: res.data
      });
      dispatch(clearErrors());
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data
      });
    });
};
export const deleteMeme = (memeId) => (dispatch) => {
  axios
    .delete(`/meme/${memeId}`)
    .then(() => {
      dispatch({ type: DELETE_MEME, payload: memeId });
    })
    .catch((err) => console.log(err));
};

export const getUserData = (userHandle) => (dispatch) => {
  dispatch({ type: LOADING_DATA });
  axios
    .get(`/user/${userHandle}`)
    .then((res) => {
      dispatch({
        type: SET_MEMES,
        payload: res.data.user
      });
    })
    .catch((err) => console.log(err));
};

export const clearErrors = () => (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};

export const setRandomMeme = () => (dispatch, getState) => {
  const memes = getState().data.memes;
  const randomIndex = Math.floor(Math.random() * memes.length);
  const selectedMeme = memes[randomIndex];
  dispatch({ type: SET_RANDOM_MEME, payload: selectedMeme });
};

// dataActions.js
export const getUserProfileData = (handle) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({ type: LOADING_UI });
    axios
      .get(`/user/${handle}`)
      .then((res) => {
        dispatch({
          type: GET_USER_PROFILE_DATA,
          payload: res.data
        });
        resolve(res.data);
        dispatch({ type: STOP_LOADING_UI });
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};

