import React, { Component } from 'react';
import PropTypes from 'prop-types';
// MUI Stuff
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
// Redux stuff
import { connect } from 'react-redux';
import { submitComment } from '../redux/actions/dataActions';


class CommentForm extends Component {
  state = {
    body: '',
    errors: {}
  };

  componentDidUpdate(prevProps) {
    // If new errors have arrived
    if (this.props.UI.errors !== prevProps.UI.errors) {
      this.setState({ errors: this.props.UI.errors });
    }
    // If errors are cleared and not loading
    if (!this.props.UI.errors && !this.props.UI.loading && prevProps.UI.loading) {
      this.setState({ body: '' });
    }
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  
  // Inside CommentForm component
  handleSubmit = (event) => {
    event.preventDefault();
    this.props.submitComment(this.props.memeId, { body: this.state.body });
    this.props.onCommentSubmitted();
  };

  render() {
    const { authenticated } = this.props;
    const errors = this.state.errors;

    const commentFormMarkup = authenticated ? (
      <Grid item sm={12} sx={{
        backgroundColor:'white',
        textAlign: 'center',
        maxWidth: '500px',
        margin: '0 auto',
        padding: '20px'
      }}>
        <form onSubmit={this.handleSubmit}>
          <TextField
            name="body"
            type="text"
            label="Comment on meme"
            error={errors.comment ? true : false}
            helperText={errors.comment}
            value={this.state.body}
            onChange={this.handleChange}
            fullWidth
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{
              marginTop: '10px'
            }}
          >
            Submit
          </Button>
        </form>
      </Grid>
    ) : null;
    return commentFormMarkup;
  }
}

CommentForm.propTypes = {
  submitComment: PropTypes.func.isRequired,
  UI: PropTypes.object.isRequired,
  memeId: PropTypes.string.isRequired,
  authenticated: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  UI: state.UI,
  authenticated: state.user.authenticated
});

export default connect(
  mapStateToProps,
  { submitComment }
)(CommentForm);