import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import '../App.css';
//Meme Component
import MemeCard from "../component/MemeCard";
import Comments from "../component/Comments";
//MUI
import CircularProgress from '@mui/material/CircularProgress';
//Redux
import { useDispatch, useSelector } from 'react-redux';
import { getMeme, likeMeme, unlikeMeme, dislikeMeme, undislikeMeme } from '../redux/actions/dataActions'; 

const Meme = () => {
  const dispatch = useDispatch();
  const meme = useSelector((state) => state.data.meme);
  const loading = useSelector((state) => state.UI.loading);
  const user = useSelector((state) => state.user);
  const comments =  meme.comments || [];

  useEffect(() => {
    window.scrollTo(0, 0);
    const currentUrl = window.location.href.split('/').pop(); 
    dispatch(getMeme(currentUrl));
  }, [dispatch]); 

  const handleLikeMeme = (memeId) => {
    dispatch(likeMeme(memeId));
  };

  const handleUnlikeMeme = (memeId) => {
    dispatch(unlikeMeme(memeId));
  };

  const handleDislikeMeme = (memeId) => {
    dispatch(dislikeMeme(memeId));
  };

  const handleUndislikeMeme = (memeId) => {
    dispatch(undislikeMeme(memeId));
  };

  // Determine what to render based on loading and error states
  let memeMarkup;
  if (loading) {
    memeMarkup = <CircularProgress />;
  } else if (meme.userHandle) { 
    memeMarkup = (
      <div>
      <MemeCard 
        key={meme.memeId} 
        meme={meme}
        user={user}
        likeMeme={() => handleLikeMeme(meme.memeId)} 
        unlikeMeme={() => handleUnlikeMeme(meme.memeId)}
        dislikeMeme={() => handleDislikeMeme(meme.memeId)} 
        undislikeMeme={() => handleUndislikeMeme(meme.memeId)}
      />
      <Comments comments={comments} />
    </div>
    );
  } else {
    memeMarkup = <p>Error: Meme data is not available. Please check Meme ID.</p>
  }

  // Return JSX
  return (
    <div>
      {memeMarkup}
    </div>
  );
};

Meme.propTypes = {
  likeMeme: PropTypes.func,
  unlikeMeme: PropTypes.func,
  dislikeMeme: PropTypes.func,
  undislikeMeme: PropTypes.func,
};
  
  
export default Meme;
