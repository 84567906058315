// Importing required libraries, modules, and components
import React, { useEffect, useState } from "react"; 
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import "./App.css";
//Redux
import store from './redux/store';
import { SET_AUTHENTICATED } from './redux/types';
import { logoutUser, getUserData } from './redux/actions/userActions';
//Components
import Navbar from "./component/Navbar";
import Sidebar from "./component/Sidebar";
import Random from "./component/Random";
import OverlaySidebar from "./component/overlay/Sidebar";
import Notifications from "./component/Notifications";
//Pages
import Home from './pages/home'; 
import Login from './pages/login';
import Meme from './pages/meme';
import Profile from './pages/profile';
import Ranking from './pages/ranking';
import Search from './pages/search';
import Signup from './pages/signup';
import Upload from './pages/upload';
import User from './pages/user';


// Function to initialize application's authentication state
const initializeApp = () => {
  const token = localStorage.FBIdToken;
  if (token) {
    const decodedToken = jwtDecode(token);
    if (decodedToken.exp * 1000 < Date.now()) {
      // If token is expired, logout user
      store.dispatch(logoutUser());
    } else {
      // If token is valid, set authentication and fetch user data
      store.dispatch({ type: SET_AUTHENTICATED });
      axios.defaults.headers.common['Authorization'] = token;
      store.dispatch(getUserData());
    }
  }
};

// Set default API endpoint
axios.defaults.baseURL = "https://us-central1-snekpics-da06d.cloudfunctions.net/api";

// Main App component
function App() {
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const [navQuery, setNavQuery] = useState("");

  // Execute initializer function when App mounts
  useEffect(() => {
    initializeApp();
  }, []);

  return (
    <div className="App">
      <Router>
        <Navbar 
          searchQuery={navQuery} 
          onSearchChange={setNavQuery}
          isSidebarVisible={isSidebarVisible} 
          setIsSidebarVisible={setIsSidebarVisible} 
        />  {/* Navigation bar component */}
        <div className="container">
          <OverlaySidebar
          isSidebarVisible={isSidebarVisible}
          setIsSidebarVisible={setIsSidebarVisible} 
          />  {/* Sidebar component */}
          <Sidebar />  {/* Sidebar component */}
          <div className="routes">
            {/* Defining application routes */}
            <Routes>
              <Route index path="/" element={<Home />} />  {/* Home Page */}
              <Route path="/latest" element={<Home />} />  {/* Latest memes */}
              <Route path="/login" element={<Login />} />  {/* Login Page */}
              <Route path="/signup" element={<Signup />} />  {/* Signup Page */}
              <Route path="/user/:handle" element={<User />} />  {/* User Profile */}
              <Route path="/profile/:handle" element={<Profile />} />  {/* Authenticated Profile */}
              <Route path="/meme/:memeId" element={<Meme />} />  {/* Individual Meme Page */}
              <Route path="/upload" element={<Upload />} />  {/* Upload Meme */}
              <Route path="/random" element={<Random />} />  {/* Random Meme */}
              <Route path="/ranking" element={<Ranking />} />  {/* Rankings */}
              <Route path="/notifications" element={<Notifications />} />  {/* Notifications */}
              <Route path="/search" element={<Search navQuery={navQuery} />} />  {/* Search Page */}
              <Route path="*" element={<h1 style={{color: 'white'}} >404 Not Found!</h1>} />  {/* 404 Page */}
            </Routes>
          </div>
        </div>
      </Router>
    </div>
  );
}

export default App;
