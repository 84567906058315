import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { Link } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import GradeIcon from '@mui/icons-material/Grade';
import ShareIcon from '@mui/icons-material/Share';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AddCommentIcon from '@mui/icons-material/AddComment';

const Memes = ( { memes }) => {
    dayjs.extend(relativeTime);

    const calculateLikesRatio = (likeCount, dislikeCount) => {
        if (dislikeCount === 0) {
        if (likeCount === 0) {
            return 0;
        } else {
            return 100;
        }
        } else if (likeCount === 0) {;
            return -dislikeCount; 
        } else {
        const ratio = Math.round((likeCount / (likeCount + dislikeCount)) * 100);
            return ratio;
        }  
    };

    const handleDownloadClick = (meme) => {
        const link = document.createElement('a');
        link.href = meme.memeImageUrl;
        link.download = `memes_${meme.memeImageName}`;
        link.target = '_blank'
        link.click();
    };

    const copyToClipboard = async (meme) => {
        try {
        await navigator.clipboard.writeText(`https://snek.pics/meme/${meme.memeId}`);
        } catch (error) {
        console.error('Failed to copy text: ', error);
        }
    };

  return (
    <div>
      {memes.map((meme) => (
        <Card key={meme.memeId} sx={{ 
            maxWidth: '100%',
            marginRight: 'auto',
            marginLeft: 'auto', 
            marginBottom: '50px',
            outline: '4px solid #353535',
            textAlign: 'left'
            }}>
        <CardHeader
            sx = {{
            backgroundColor: '#833fb6',
            fontFamily: 'Farro',
            maxHeight: '30px',
            textDecoration: 'none'
            }}

            disableTypography={true}
            
            action={
            <IconButton 
            sx={{
                width: '45px',
                height: '30px'
            }}>
                <GradeIcon sx={{
                width: '35px',
                height: 'auto'
            }}/>
            </IconButton>
            }
            title={<Typography
            component={Link} 
            to={"/meme/"+ meme.memeId}
            sx={{
                fontFamily: 'Farro',
                fontSize: { xs: 'medium', md: 'large', lg: 'x-large' },
                color: 'white',
                fontWeight: 'bolder',
                textDecoration: 'none'
            }}>{meme.body}</Typography>}

            subheader={<Typography
                ariant="body2" 
                color="silver"
                sx={{
                    fontSize: 'x-small',
                    fontFamily: 'Farro'
                }}>
            {dayjs(meme.createdAt).fromNow()}
            </Typography>}
            
            
        />
        <CardMedia
            component="img"
            src={meme.memeImageUrl}
            alt="meme"
            sx={{
                maxHeight: 'auto',
                width: '100%',
                marginRight: '50px'
            }}
            />
            <CardActions 
                sx={{
                backgroundColor: '#833fb6',
                color: '#02a2e6',
                justifyContent: 'space-evenly',
                display: 'flex'
            }}>
            <IconButton>
                <AddCommentIcon sx={{color: 'whitesmoke'}} />
                <Typography 
                sx={{
                    marginLeft: '5px',
                    fontSize: 'small',
                    fontFamily: 'Farro',
                    color: 'whitesmoke'
                }}>{meme.commentCount}</Typography>
            </IconButton>
            <IconButton >
                <ThumbUpAltIcon sx={{ color: 'orange' }} />
                <Typography 
                sx={{
                    marginLeft: '5px',
                    fontSize: 'small',
                    fontFamily: 'Farro',
                    color: 'whitesmoke'
                }}>{meme.likeCount}</Typography>
              </IconButton>
            <IconButton>
                <ThumbDownIcon sx={{color: 'gray'}}/>
            </IconButton>
            <Typography>{calculateLikesRatio(meme.likeCount, meme.dislikeCount)}%</Typography>
            <IconButton aria-label="download" onClick={() => handleDownloadClick(meme)}>
                <FileDownloadIcon />
            </IconButton>
            <IconButton aria-label="share" onClick={() => copyToClipboard(meme)}>
                <ShareIcon color="primary" />
            </IconButton>
            </CardActions>
            <div className="content-tags">
                {meme.tags.map((tag, tagIndex) => (
                // Using the Link component to make each tag clickable
                <Link 
                    to={`/search?tag=${tag}`}
                    style={{ textDecoration: 'none' }} // Optional: Style as needed
                    key={tagIndex}
                >
                    <button className="tag-button">{tag}</button>
                </Link>
                ))}
            </div>
        </Card>
        ))}
    </div>
  );
}

Memes.propTypes = {
    memes: PropTypes.arrayOf(PropTypes.shape({
      memeId: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired,
      memeImageUrl: PropTypes.string.isRequired,
      likeCount: PropTypes.number.isRequired,
      dislikeCount: PropTypes.number.isRequired,
      commentCount: PropTypes.number.isRequired,
    })).isRequired
  };
  
  export default Memes;