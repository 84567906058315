import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
// import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LinkIcon from '@mui/icons-material/Link';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { Avatar } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';

const EditProfile = ({ profile, toggleEditing} ) => {

  const { handle, createdAt, imageUrl, bio, website, location } = profile;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        maxWidth: '100%',
        margin: '0 auto',
        background: 'linear-gradient(to top, #bc41dc, #02a2e6)',
        border: '2px solid orange',
        borderRadius: '20px',
        padding: '20px 10px 20px 20px',
        marginBottom: '50px',
      }}
    >
      <Button onClick={toggleEditing}  sx={{ position: 'relative', zIndex: 7, width: '100%', height: '100%'}}>
      <EditIcon fontSize='small'
        sx={{ position: 'absolute', color: 'black', top: '-10px', right: '0'  }}
      />
      </Button>
      <Avatar
        sx={{
          width: 120,
          height: 120,
          border: '2px solid orange',
          backgroundColor: 'white',
          marginBottom: '15px'
        }}
        src={imageUrl}
        alt="profile image"
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          fontFamily: 'Farro',
          fontSize: 'small',
          maxWidth: '360px',
          color: 'white',
          marginLeft: '15px',
          textAlign: 'left', 
        }}
      >
        <Typography component="div" sx={{ fontSize: { xs: 'medium', md: 'large', lg: 'large' }, fontWeight: 'bold' }}>
          {handle}
        </Typography>
        <Typography component="div" sx={{ 
            fontSize: { xs: 'small', md: 'medium', lg: 'medium' }, 
            marginTop: '10px', 
            marginBottom: '20px',
             }}>
          {bio}
        </Typography>
        {location && (
          <Typography component="div" variant="p" sx={{ display: 'flex', alignItems: 'flex-end' }}>
            <LocationOnIcon fontSize="small" sx={{ marginRight: '5px', color: 'orange' }} />
            {location}
          </Typography>
        )}
        {website && (
          <Typography component="div" variant="p" sx={{ display: 'flex', alignItems: 'flex-end' }}>
            <LinkIcon fontSize="small" sx={{ marginRight: '5px', color: 'orange' }} />
            <a
              href={website}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                zIndex: 1,
                textDecoration: 'none',
                color: 'white',
                transition: 'color 0.3s',
              }}
              onMouseEnter={(e) => (e.target.style.color = 'orange')}
              onMouseLeave={(e) => (e.target.style.color = 'white')}
            >
              {' '}
              {website}
            </a>
          </Typography>
        )}
        <Typography component="div" variant="p" sx={{ display: 'flex', alignItems: 'flex-end' }}>
          <CalendarTodayIcon fontSize="small" sx={{ marginRight: '5px', color: 'orange' }} />
          <span>Joined {dayjs(createdAt).format('MMM YYYY')}</span>
        </Typography>
      </Box>
    </Box>
  );
};

EditProfile.propTypes = {
  profile: PropTypes.object.isRequired,
};

export default EditProfile;
