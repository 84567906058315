import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import StaticProfile from '../component/profile/StaticProfile';
import MemeCard from '../component/MemeCard';
//MUI
import CircularProgress from '@mui/material/CircularProgress';
//REDUX
import { useDispatch, useSelector } from 'react-redux';
import { getUserProfileData, likeMeme, unlikeMeme, dislikeMeme, undislikeMeme } from '../redux/actions/dataActions';

const User = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.UI.loading);
  const user = useSelector((state) => state.user);
  const profile = useSelector((state) => state.data.profile);

  useEffect(() => {
    window.scrollTo(0, 0);
    const currentUrl = window.location.href;
    const handle = currentUrl.split('/').pop();

    dispatch(getUserProfileData(handle));
  }, [dispatch]);

  // Handle Like and Unlike using the dispatch hook
  const handleLikeMeme = (memeId) => {
    dispatch(likeMeme(memeId));
  };

  const handleUnlikeMeme = (memeId) => {
    dispatch(unlikeMeme(memeId));
  };

  const handleDislikeMeme = (memeId) => {
    dispatch(dislikeMeme(memeId));
  };

  const handleUndislikeMeme = (memeId) => {
    dispatch(undislikeMeme(memeId));
  };

  let userMemesMarkup;
  if (loading) {
    userMemesMarkup = <CircularProgress />;
  } else if (profile) { 
    userMemesMarkup = (
      <div>
        <StaticProfile profile={profile.user} />
        {profile.memes.map((meme) => (
        <div style={{ marginTop: '50px' }} key={meme.memeId}>
          {meme.length === 0 ? (
            <div className="not-found">
              <h3>No memes posted yet!</h3>
            </div>
          ) : (
              <MemeCard 
              meme={meme} 
              user={user}
              likeMeme={() => handleLikeMeme(meme.memeId)} 
              unlikeMeme={() => handleUnlikeMeme(meme.memeId)}
              dislikeMeme={() => handleDislikeMeme(meme.memeId)} 
              undislikeMeme={() => handleUndislikeMeme(meme.memeId)}
              />
            
          )}
        </div>
        ))}
      </div>
    );
  } else {
    userMemesMarkup = <p>Error: User not found...</p>
  }

  return (
    <div style={{maxWidth: '500px', margin: '0 auto'}}>
     {userMemesMarkup}
    </div>
  );
}

User.propTypes = {
  likeMeme: PropTypes.func,
  unlikeMeme: PropTypes.func,
  dislikeMeme: PropTypes.func,
  undislikeMeme: PropTypes.func
};

export default User;
