import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit'

import userReducer from './reducers/userReducer';
import dataReducer from './reducers/dataReducer';
import uiReducer from './reducers/uiReducer';

const initialState = {};

const reducers = combineReducers({
  user: userReducer,
  data: dataReducer,
  UI: uiReducer
});

const store = configureStore({
  reducer: reducers, 
  preloadedState: initialState, 
});

export default store;
