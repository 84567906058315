// Import modules
import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import '../App.css';
//Meme Component
import MemeCard from "../component/MemeCard";
//MUI
import CircularProgress from '@mui/material/CircularProgress';
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
//Redux
import { useDispatch, useSelector } from 'react-redux';
import { getMemes, likeMeme, unlikeMeme, dislikeMeme, undislikeMeme } from '../redux/actions/dataActions';

const Home = () => {
  // Use Redux hooks to get access to dispatch and state
  const dispatch = useDispatch();
  const memes = useSelector((state) => state.data.memes);
  const loading = useSelector((state) => state.UI.loading);
  const user = useSelector((state) => state.user);

  const [displayCount, setDisplayCount] = useState(20);

  // Dispatch the getMemes action when the component mounts
  useEffect(() => {
    dispatch(getMemes());
  }, [dispatch]);

  // Handle Like and Unlike using the dispatch hook
  const handleLikeMeme = (memeId) => {
    dispatch(likeMeme(memeId));
  };

  const handleUnlikeMeme = (memeId) => {
    dispatch(unlikeMeme(memeId));
  };

  const handleDislikeMeme = (memeId) => {
    dispatch(dislikeMeme(memeId));
  };

  const handleUndislikeMeme = (memeId) => {
    dispatch(undislikeMeme(memeId));
  };

  const loadMoreMemes = () => {
    setDisplayCount(displayCount + 20);
  };

  const scrollToTop = () => {
    const scrollDuration = 500;
    const scrollStep = -window.scrollY / (scrollDuration / 15);
    const scrollInterval = setInterval(() => {
      if (window.scrollY !== 0) {
        window.scrollBy(0, scrollStep);
      } else {
        clearInterval(scrollInterval);
      }
    }, 15);
  };
  

  const displayedMemes = memes.slice(0, displayCount);

  // Determine what to render based on loading and error states
  let recentMemesMarkup;
  if (loading) {
    recentMemesMarkup = <CircularProgress />;
  } else if (displayedMemes && Array.isArray(displayedMemes)) { 
    recentMemesMarkup = (
      <>
     {displayedMemes.map((meme, index) => (
      <div key={meme.memeId}>
        <MemeCard 
        key={meme.memeId} 
        meme={meme} 
        user={user}
        likeMeme={() => handleLikeMeme(meme.memeId)} 
        unlikeMeme={() => handleUnlikeMeme(meme.memeId)}
        dislikeMeme={() => handleDislikeMeme(meme.memeId)} 
        undislikeMeme={() => handleUndislikeMeme(meme.memeId)}
      />
      {(index === displayedMemes.length - 1) && (
        <>
          {displayCount >= memes.length ? (
            <p />
          ) : (
            <Button onClick={loadMoreMemes}
              sx={{ 
                backgroundColor: '#9f35f1',
                color: 'white',
                minWidth: '200px',
                fontFamily: 'Farro',
                fontSize: 'large',
                textTransform: 'none',
                border: '3px solid orange',
                borderRadius: '10px',
                marginBottom: '30px',
                '&:hover': {
                  backgroundColor: '#9f35f1',
                  color: 'orange',
                }
              }}
                >Load More Memes</Button>
              )}
              <IconButton
              color="primary"
              onClick={scrollToTop}
              style={{ position: 'fixed', bottom: '20px', right: '20px', opacity: 0.3,
            }}
            >
              <ArrowCircleUpIcon sx={{ height: '50px', width: '50px' }} />
            </IconButton>
            </>
          )}
        </div>
      ))}
    </>
    );
  } else {
    recentMemesMarkup = <p>Error: Memes data is not available. Please report this issue.</p>
  }

  // Return JSX
  return (
    <div className="routes">
      {recentMemesMarkup}
    </div>
  );
};

Home.propTypes = {
  likeMeme: PropTypes.func,
  unlikeMeme: PropTypes.func,
  dislikeMeme: PropTypes.func,
  undislikeMeme: PropTypes.func,
};

// Export the component for use in other parts of the application
export default Home;