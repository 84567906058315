// Importing required libraries, modules, and components
import React from "react";
import { Link } from "react-router-dom";
import ReactModal from 'react-modal';
//Local Components and Images
import Ad from "./Ad"
import MoreInfo from './MoreInfo';
import Trending from "./Trending";
import logo from '../images/twitter-icon.png';
//MUI
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import { Avatar, useMediaQuery, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import LogoutIcon from '@mui/icons-material/Logout';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
//Redux
import { connect, useDispatch } from 'react-redux';
import store from '../redux/store';
import { logoutUser } from '../redux/actions/userActions';
import { setRandomMeme } from "../redux/actions/dataActions";
import '../App.css';

ReactModal.setAppElement('#root');

function Sidebar({ authenticated, user  }) {
  const dispatch = useDispatch();
  const handleLogout = () => {
    store.dispatch(logoutUser());
  };
  
  const isSmallScreen = useMediaQuery('(max-width:768px)');

  return (
    <Toolbar className={`button-section ${isSmallScreen ? 'hide-sidebar' : ''}`}>
      <div className='button-section'>

      {authenticated  ? (
        <div>
          <Avatar 
            component={Link} 
            to={"/profile/" + user.credentials.handle}
            alt={user.credentials.handle}
            src={user.credentials.imageUrl}
            sx={{
              border: '3px solid orange',
              height: '120px',
              width: '120px',
              margin: '0 auto 10px auto ',
              '&:hover': {
                backgroundColor: '#7b26c9',
              }
              }}>{user.credentials.handle}
            </Avatar>
            <Typography 
              component={Link} 
              to={"/profile/" + user.credentials.handle} 
              sx={{ color: 'white', fontWeight: 'bold', textDecoration: 'none', '&:hover': {
                color: 'orange'
              }}}
            >
              {user.credentials.handle}
            </Typography>
            <br />
            <IconButton component={Link} to="/notifications">
            <NotificationsActiveIcon 
              sx={{
                color:"white", 
                fontSize:"x-large",
                '&:hover': {
                  color: 'orange',
                }}} />
            </IconButton>
            <IconButton component={Link} to="/favorites">
            <FolderSpecialIcon 
              sx={{
                color:"white", 
                fontSize:"x-large",
                '&:hover': {
                  color: 'orange',
                }}} />
            </IconButton>
            <IconButton component={Link} to="/upload">
              <CloudUploadIcon
                sx={{
                  color:"white", 
                  fontSize:"x-large",
                  '&:hover': {
                    color: 'orange',
                  }
                }} />
            </IconButton>
            <IconButton onClick={handleLogout}>
              <LogoutIcon type="button" 
                sx={{
                  color:"white", 
                  fontSize:"x-large",
                  '&:hover': {
                    color: 'orange',
                  }
                }} 
                 />
              </IconButton>
        </div>
      ) : (
        <Button 
          component={Link} 
          to="/login"
          className="button"
          variant="contained"
          sx={{
            backgroundColor: '#9f35f1',
            minWidth: '200px',
            fontFamily: 'Farro',
            fontSize: 'large',
            textTransform: 'none',
            border: '3px solid orange',
            borderRadius: '10px',
            '&:hover': {
              backgroundColor: '#7b26c9',
            }
             }}>
          Login
        </Button>
      )}
      <hr className="divider" />
      <Button 
          component={Link} 
          to="/latest"
          className="button"
          variant="contained"
          sx={{ 
            backgroundColor: '#9f35f1',
            minWidth: '200px',
            fontFamily: 'Farro',
            fontSize: 'large',
            textTransform: 'none',
            border: '3px solid orange',
            borderRadius: '10px',
            marginBottom: '10px',
            '&:hover': {
              backgroundColor: '#9f35f1',
              color: 'orange',
            }
             }}> Latest Posts</Button><br />
      <Button 
          component={Link} to="/random" 
          onClick={() => dispatch(setRandomMeme())}
          className="button"
          variant="contained"
          sx={{ 
            backgroundColor: '#9f35f1',
            minWidth: '200px',
            fontFamily: 'Farro',
            fontSize: 'large',
            textTransform: 'none',
            border: '3px solid orange',
            borderRadius: '10px',
            marginBottom: '10px',
            '&:hover': {
              backgroundColor: '#9f35f1',
              color: 'orange',
            }
             }}>Random</Button><br />
      <Button 
          component={Link} 
          to="/ranking"
          className="button"
          variant="contained"
          sx={{ 
            backgroundColor: '#9f35f1',
            minWidth: '200px',
            fontFamily: 'Farro',
            fontSize: 'large',
            textTransform: 'none',
            border: '3px solid orange',
            borderRadius: '10px',
            '&:hover': {
              backgroundColor: '#9f35f1',
              color: 'orange',
            }
             }}>🏆Ranking🏆</Button><br />
      <hr className="divider" />
        <Trending />
      <hr className="divider" />
      <MoreInfo />
      <hr className="divider" />
      <footer className="App-footer">
      <a href="https://twitter.com/snek_pics" target="_blank" rel="noreferrer">
          <img src={logo} alt="Logo" className="logo" />
      </a>
      <p className="send-to">Donate to $snekpics</p>
      <a className='whitepaper' href='https://snekpics.gitbook.io/whitepaper/' target="_blank" rel="noreferrer">📝Whitepaper📝</a>
      <p className="footer">© 2023 SNEKPICS</p>
      </footer>
      <Ad />
      </div>
    </Toolbar>
    
  );
}

const mapStateToProps = (state) => ({
  authenticated: state.user.authenticated,
  user: state.user
});

export default connect(mapStateToProps)(Sidebar);