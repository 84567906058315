import {
  SET_MEMES,
  LIKE_MEME,
  UNLIKE_MEME,
  DISLIKE_MEME,
  UNDISLIKE_MEME,
  LOADING_DATA,
  DELETE_MEME,
  POST_MEME,
  SET_MEME,
  SUBMIT_COMMENT,
  SET_RANDOM_MEME,
  GET_USER_PROFILE_DATA
} from '../types';

const initialState = {
  memes: [],
  meme: {},
  randomMeme: null,
  loading: false
};

function dataReducer(state = initialState, action) {
  switch (action.type) {
    case LOADING_DATA:
      return {
        ...state,
        loading: true
      };
    case SET_MEMES:
      return {
        ...state,
        memes: action.payload
      };
    case SET_MEME:
      return {
        ...state,
        meme: action.payload
      };
    case LIKE_MEME:
    case UNLIKE_MEME:
    case DISLIKE_MEME:
    case UNDISLIKE_MEME:
      let index = state.memes.findIndex(
        (meme) => meme.memeId === action.payload.memeId
      );
      
      const updatedMemes = [...state.memes];
      updatedMemes[index] = action.payload;
    
      const updatedMeme = state.meme.memeId === action.payload.memeId 
        ? { ...action.payload, comments: state.meme.comments }
        : state.meme;
      
      const updatedRandomMeme = state.randomMeme && state.randomMeme.memeId === action.payload.memeId 
        ? action.payload 
        : state.randomMeme;
    
      return {
        ...state,
        memes: updatedMemes,
        meme: updatedMeme,
        randomMeme: updatedRandomMeme
      }; 
    case DELETE_MEME:
      index = state.memes.findIndex(
        (meme) => meme.memeId === action.payload
      );
      state.memes.splice(index, 1);
      return {
        ...state
      };
    case POST_MEME:
      return {
        ...state,
        memes: [action.payload, ...state.memes]
      };
    case SUBMIT_COMMENT:
      return {
        ...state,
        meme: {
          ...state.meme,
          comments: [action.payload, ...state.meme.comments]
        }
      };
    case SET_RANDOM_MEME:
    return {
      ...state,
      randomMeme: action.payload
    };
    case GET_USER_PROFILE_DATA:
      return {
        ...state,
        profile: action.payload
      };
    // other cases
    default:
      return state;
  }
}

export default dataReducer;