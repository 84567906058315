import React, { useState } from 'react';
import ReactModal from 'react-modal';
import '../App.css'

const MoreInfo = () => {
  const [showSiteRulesModal, setShowSiteRulesModal] = useState(false);
  const [showTermsOfServiceModal, setShowTermsOfServiceModal] = useState(false);
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);

  const toggleSiteRulesModal = () => {
    setShowSiteRulesModal(!showSiteRulesModal);
  };

  const toggleTermsOfServiceModal = () => {
    setShowTermsOfServiceModal(!showTermsOfServiceModal);
  };

  const togglePrivacyModal = () => {
    setShowPrivacyModal(!showPrivacyModal);
  };

  return (
    <div className='more-info'>
        <button className='more-info-button' onClick={toggleSiteRulesModal}>Site Rules</button>
        <span>|</span>
        <button className='more-info-button' onClick={toggleTermsOfServiceModal}>Terms of Service</button>
        <span>|</span>
        <button className='more-info-button' onClick={togglePrivacyModal}>Privacy</button>

        <ReactModal
        isOpen={showSiteRulesModal}
        onRequestClose={toggleSiteRulesModal}
        className="modal"
        overlayClassName="overlay"
        >
        <div className="modal-content">
            <h2>Site Rules</h2>
            <ul>
            <li>
                Illegal content: Posting or sharing any content that is illegal or
                violates any local, national, or international laws is strictly
                prohibited.
            </li>
            <li>
                Obscene or explicit content: Avoid posting or sharing pictures or
                comments that contain explicit, pornographic, or sexually explicit
                material.
            </li>
            <li>
                Copyright infringement: Respect intellectual property rights and do not
                post copyrighted material without proper authorization.
            </li>
            <li>
                Offensive or discriminatory content: Do not post or share content that
                is offensive, discriminatory, or promotes hatred based on factors such
                as race, ethnicity, religion, gender, sexual orientation, or disability.
            </li>
            <li>
                Personal information: Avoid posting or sharing personal information
                about yourself or others without proper consent.
            </li>
            <li>
                Harassment or cyberbullying: Do not engage in any form of harassment,
                cyberbullying, or targeted attacks towards individuals or groups.
            </li>
            <li>
                Spam or unrelated content: Avoid posting irrelevant or repetitive
                content that does not contribute to the discussion or purpose of the
                website.
            </li>
            <li>
                Malicious content or links: Do not post or share any content or links
                that contain malware, viruses, or any other form of malicious software.
            </li>
            </ul>
            <button className="modal-close" onClick={toggleSiteRulesModal}>
            <span>&times;</span>
            </button>
        </div>
        </ReactModal>


        {/* Terms of Service Modal */}
        <ReactModal
            isOpen={showTermsOfServiceModal}
            onRequestClose={toggleTermsOfServiceModal}
            className="modal"
            overlayClassName="overlay"
        >
            <div className="modal-content">
            {/* Modal content */}
            <h2>Terms of Service</h2>
            <p>1. Acceptance of Terms</p>
            <p>By accessing and using this website (hereinafter referred to as "the Site"), you accept and agree to be bound by these Terms of Service. If you do not agree to these terms, please refrain from using the Site.</p>
            
            <p>2. Intellectual Property Rights</p>
            <p>The content and materials available on the Site, including but not limited to text, graphics, logos, images, videos, and software, are the property of the site owner and are protected by applicable copyright and intellectual property laws. You may not use, reproduce, distribute, or modify any of the content without prior written permission from the site owner.</p>
            
            <p>3. User Responsibilities</p>
            <p>a. You are solely responsible for any content you post or upload to the Site. By submitting content, you grant the site owner a non-exclusive, royalty-free, worldwide license to use, reproduce, modify, adapt, publish, and display the content for any purpose.</p>
            <p>b. You agree not to engage in any activities that may disrupt or interfere with the proper functioning of the Site or its services.</p>
            <p>c. You agree not to upload or transmit any content that is unlawful, harmful, threatening, abusive, defamatory, obscene, or otherwise objectionable.</p>
            
            <p>4. Limitation of Liability</p>
            <p>The site owner shall not be liable for any damages, whether direct, indirect, incidental, or consequential, arising out of or in connection with the use of the Site or its services. This includes any loss of data, loss of profits, or loss of business opportunities.</p>
            
            <p>5. Third-Party Links</p>
            <p>The Site may contain links to third-party websites or services that are not owned or controlled by the site owner. The site owner has no control over and assumes no responsibility for the content, privacy policies, or practices of any third-party sites or services. You acknowledge and agree that the site owner shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods, or services available on or through any third-party sites or services.</p>
            
            <p>6. Termination</p>
            <p>The site owner reserves the right, in its sole discretion, to terminate or suspend your access to the Site at any time, without prior notice or liability, for any reason, including without limitation if you breach these Terms of Service.</p>
            
            <p>7. Governing Law</p>
            <p>These Terms of Service shall be governed by and construed in accordance with the laws of [your jurisdiction]. Any dispute arising under or in connection with these terms shall be subject to the exclusive jurisdiction of the courts of [your jurisdiction].</p>
            
            <p>8. Changes to the Terms of Service</p>
            <p>The site owner reserves the right to modify or replace these Terms of Service at any time. Your continued use of the Site after any such changes constitutes your acceptance of the new terms.</p>
            
            <p>Please read these Terms of Service carefully and contact us if you have any questions.</p>

            <p>Last updated: [06.14.2023]</p>
            <button className="modal-close" onClick={toggleTermsOfServiceModal}>
                <span>&times;</span>
            </button>
            </div>
        </ReactModal>

        {/* Privacy Modal */}
        <ReactModal
            isOpen={showPrivacyModal}
            onRequestClose={togglePrivacyModal}
            className="modal"
            overlayClassName="overlay"
        >
            <div className="modal-content">
            {/* Modal content */}
            <h2>Privacy Policy</h2>
            <p>At SnekPics, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy explains how we collect, use, and safeguard the information you provide when visiting our website or using our app.</p>

            <h3>Information We Collect</h3>
            <p>We may collect information from you when you visit our website, use our app, create a user account on SnekPics, or contact us through our contact form. We do not collect any personally identifiable information such as IP addresses or store cookies when using this site.</p>

            <h3>User Account Creation</h3>
            <p>When creating a user account on SnekPics, you may be asked to provide certain information such as your wallet address and username. However, we do not collect any other personally identifiable information during the account creation process.</p>

            <h3>How We Use Your Information</h3>
            <p>The information we collect may be used to personalize your experience, improve our website and app, enhance customer service, perform data analytics, ensure security, and show relevant and personalized ads.</p>

            <h3>Information Security</h3>
            <p>We implement various security measures to maintain the safety of your personal information. We use HTTPS to transfer your information, encrypt sensitive data, and protect against unauthorized access. Unless otherwise requested by you we may keep some of the collected information indefinitely.</p>

            <h3>Disclosure to Third Parties</h3>
            <p>We do not sell, trade, or otherwise transfer your personally identifiable information to outside parties. Trusted third parties that assist us in operating our website and app are required to keep your information confidential and comply with privacy laws and regulations.</p>

            <h3>Third party Links</h3>
            <p>At our discretion, we may include links or advertisements to third party products or services on our website. These third-party sites have separate and independent privacy policies. We therefore have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these sites.</p>

            <h3>Terms and Conditions</h3>
            <p>Please review our Terms and Conditions section establishing the use, disclaimers, and limitations of liability governing the use of our website and app.</p>

            <h3>User Consent</h3>
            <p>By using our website and/or app, you consent to this privacy policy.</p>

            <h3>Changes to the Privacy Policy</h3>
            <p>If we change our privacy policy, we will post those changes on this page.</p>

            <h3>Request account Removal</h3>
            <p>To remove your user account, simply contact us at submit@snek.pics and request the deletion. Please note we will to confirm ownership, but will make this feature more accessible in the future. Once your account is deleted, all associated data will be permanently removed.</p>

            <h3>Contacting Us</h3>
            <p>If you have any questions or concerns regarding this Privacy Policy, you may contact us at <a href="mailto:submit@snek.pics">submit@snek.pics</a>.</p>

            <button className="modal-close" onClick={togglePrivacyModal}>
                <span>&times;</span>
            </button>
            </div>
        </ReactModal>
        </div>
        );
}

export default MoreInfo;