import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from 'axios';
//Components and Images
import EditProfile from '../component/profile/EditProfile';
import Memes from '../component/profile/Memes';
import Form from '../component/profile/Form';
//MUI
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
//Redux
import { connect } from 'react-redux';
import { getUserProfileData } from '../redux/actions/dataActions';
import { editUserDetails, uploadImage } from '../redux/actions/userActions';

class profile extends Component {
  state = {
    loading: true,
    profile: null,
    memeIdParam: null,
    authorized: true,
    handle: null,
    loaded: false,
    isEditing: false,
    image: null,
  };

  async componentDidMount() {
    const handle = window.location.pathname.split('/').pop();
    this.setState({ handle });

    this.props.getUserProfileData(handle)
  
    let loggedInHandle = this.props.user.credentials.handle

    if (!loggedInHandle) {
      try {
        await this.props.getUserProfileData(handle)
        loggedInHandle = this.props.user.credentials.handle;
      } catch (err) {
        console.error('An error occurred:', err);
      }
    }

    if (loggedInHandle !== handle) {
      this.setState({ authorized: false });
      return;
    } else if (loggedInHandle === handle) {
      axios
        .get(`/user/${handle}`)
        .then((res) => {
          this.setState({
            loading: false,
            profile: res.data.user,
            memeIdParam: res.data.memes,
            loaded: true,
          })
        })
        .catch(err => {
          console.error('An error occurred:', err);
        });
    } else {
      window.alert('Something went wrong')
    }

  }

  
  toggleEditing = () => {
    this.setState((prevState) => ({ isEditing: !prevState.isEditing }));
  };

  // Function to handle image upload
  handleImageUpload = () => {
    const { image } = this.state;
    const formData = new FormData();
    formData.append('image', image, image.name);
    this.props.uploadImage(formData); // assuming this action is responsible for uploading the image
  };

  handleImageChange = (e) => {
    const file = e.target.files[0];
    this.setState({ image: file });
  };  

  handleClose = () => {
    window.alert("Operation was successful, window will refresh.");
    window.location.reload();
  };
  

  handleSubmit = (userDetails) => {
    const editUser = {
      bio: '',
      website: '',
      location: ''
    };
  
    for (const [key, value] of Object.entries(userDetails)) {
      if (value && key !== 'image') {
        console.log(key, value)
        editUser[key] = value;
      }
    }
    
    console.log(Object.keys(editUser).length)
  
    if (Object.keys(editUser).length > 0) {
      this.props.editUserDetails(editUser);
      console.log(editUser)
    }
  
    if (userDetails.image) { 
      this.handleImageUpload();
    }

    this.handleClose();
  };

  render() {
    const { loading, profile, memeIdParam, authorized, handle } = this.state;
    const { user } = this.props; 

    if (!authorized) {
      return (
        <div style={{maxWidth: '500px', margin: '0 auto'}}>
          <h1>Error 403: Unauthorized Access</h1>
          <Typography 
          component={Link} 
          to={"/user/"+ handle}
          style={{
              display: 'flex',
              paddingLeft: '15px',
              fontFamily: 'Farro',
              fontSize: { xs: 'small', md: 'medium', lg: 'large' },
              fontWeight: 'bolder',
              color: 'black',
              padding: '5px 0px 5px 15px',
              backgroundColor: 'silver',
              textDecoration: 'none'
              }}> 
  
              <span>Click here to view {handle}'s profile.</span>
          </Typography>
        </div>
      );
    } else {
      return (
        <div style={{maxWidth: '500px', margin: '0 auto'}}>
          {loading ? (
            <CircularProgress />
          ) : (
            <div>
              {profile && <EditProfile profile={profile} toggleEditing={this.toggleEditing} />}
              {this.state.isEditing && (
                <Form
                  handleSubmit={this.handleSubmit}
                  handleImageUpload={this.handleImageUpload}
                  image={this.state.image}
                  handleImageChange={this.handleImageChange}
                />       
              )}
              <div style={{ marginTop: '50px' }}>
                {memeIdParam.length === 0 ? (
                  <div className="not-found">
                    <h3>No memes posted yet!</h3>
                  </div>
                ) : (
                  <Memes memes={memeIdParam} user={user} />
                )}
              </div>
            </div>
          )}
        </div>
      );   
    }
  }
}

profile.propTypes = {
  getUserProfileData: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  data: state.data,
  user: state.user,
  loading: state.user.loading,
});

export default connect(mapStateToProps, { getUserProfileData, editUserDetails, uploadImage })(profile);
