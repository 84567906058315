import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import MemeCard from '../component/MemeCard';
import { useDispatch, useSelector } from 'react-redux';
import { getMemes, likeMeme, unlikeMeme, dislikeMeme, undislikeMeme } from '../redux/actions/dataActions';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';

const calculateScores = (memes) => {
  const userScores = {};

  memes.forEach((meme) => {
    const handle = meme.userHandle;
    const memeScore = 1 / 7;
    const likeScore = (meme.likeCount || 0) / 7;
    const commentScore = (meme.commentCount || 0) / 7;

    const totalScore = memeScore + likeScore + commentScore;

    if (userScores[handle]) {
      userScores[handle] += totalScore;
    } else {
      userScores[handle] = totalScore;
    }
  });

  const sortedScores = Object.keys(userScores)
    .map((handle) => ({ handle, score: userScores[handle] }))
    .sort((a, b) => b.score - a.score);

  return sortedScores;
};

const renderScoreList = (scores) => {
  return (
    <div>
      <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
        {scores.map((entry, index) => (
          <li key={index} style={{ display: "flex", justifyContent: "space-between", margin: "10px 0" }}>
            {/* Linking the username to their profile page */}
            <a href={`/user/${entry.handle}`} style={{ textDecoration: 'none', color: 'black' }}>
              {entry.handle}
            </a>
            <span>{entry.score.toFixed(2)}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

const Ranking = () => {
  const dispatch = useDispatch();
  const memes = useSelector((state) => state.data.memes);
  const loading = useSelector((state) => state.UI.loading);
  const user = useSelector((state) => state.user);
  const [rank, setRank] = useState([]);
  const [selectedRanking, setSelectedRanking] = useState('GlobalScores'); // Keep track of selected ranking type
  
  useEffect(() => {
    dispatch(getMemes());
  }, [dispatch]);

  useEffect(() => {
    if (memes && Array.isArray(memes)) {
      const sortedMemes = [...memes].sort((a, b) => b.likeCount - a.likeCount);
      setRank(sortedMemes.slice(0, 7));
    }
  }, [memes]);

  const handleLikeMeme = (memeId) => {
    dispatch(likeMeme(memeId));
  };

  const handleUnlikeMeme = (memeId) => {
    dispatch(unlikeMeme(memeId));
  };

  const handleDislikeMeme = (memeId) => {
    dispatch(dislikeMeme(memeId));
  };

  const handleUndislikeMeme = (memeId) => {
    dispatch(undislikeMeme(memeId));
  };    

  let displayContent;

  if (loading) {
    displayContent = <CircularProgress />;
  } else if (memes && Array.isArray(memes)) {
    if (selectedRanking === 'Top7Memes') {
      displayContent = rank.map((meme) => (
        <MemeCard 
          key={meme.memeId} 
          meme={meme}
          user={user}
          likeMeme={() => handleLikeMeme(meme.memeId)} 
          unlikeMeme={() => handleUnlikeMeme(meme.memeId)}
          dislikeMeme={() => handleDislikeMeme(meme.memeId)} 
          undislikeMeme={() => handleUndislikeMeme(meme.memeId)}
        />
      ));
    } else if (selectedRanking === 'GlobalScores') {
      const globalScores = calculateScores(memes);
      displayContent = (
        <div style={{maxWidth: '500px', background: 'white', borderRadius: '20px', border: '2px solid orange', padding: '10px 50px 30px 50px', justifyContent: 'center', margin: '0 auto'}}>
          <h2 style={{textDecoration: 'underline'}}>Global Scores</h2>
          {renderScoreList(globalScores)}
        </div>
      );
    } else if (selectedRanking === 'MonthlyScores') {
      const currentMonth = new Date().getMonth();
      const currentYear = new Date().getFullYear();
      
      const monthlyMemes = memes.filter(meme => {
        const memeDate = new Date(meme.createdAt);
        return memeDate.getMonth() === currentMonth && memeDate.getFullYear() === currentYear;
      });

      const monthlyScores = calculateScores(monthlyMemes);
      displayContent = (
        <div style={{maxWidth: '500px', background: 'white', borderRadius: '20px', border: '2px solid orange', padding: '10px 50px 30px 50px', justifyContent: 'center', margin: '0 auto'}}>
          <h2 style={{textDecoration: 'underline'}}>Monthly Scores</h2>
          {renderScoreList(monthlyScores)}
        </div>
      );
    }
  } else {
    displayContent = <p>Error: Memes data is not available. Please refresh the page.</p>
  }

  // Return JSX
  return (
    <div>
      <Button 
        className="button"
        variant="contained"
        onClick={() => setSelectedRanking('GlobalScores')}
        sx={{
          backgroundColor: '#9f35f1',
          minWidth: '100px',
          fontFamily: 'Farro',
          fontSize: 'small',
          border: '1px solid orange',
          borderRadius: '0',
          '&:hover': {
            backgroundColor: '#7b26c9',
          }
          }}> Global Scores </Button>
      <Button 
        className="button"
        variant="contained"
        onClick={() => setSelectedRanking('MonthlyScores')}
        sx={{
          backgroundColor: '#9f35f1',
          minWidth: '100px',
          fontFamily: 'Farro',
          fontSize: 'small',
          border: '1px solid orange',
          borderRadius: '0',
          '&:hover': {
            backgroundColor: '#7b26c9',
          }
          }}> Monthly Scores </Button>
      <Button 
        className="button"
        variant="contained"
        onClick={() => setSelectedRanking('Top7Memes')}
        sx={{
          backgroundColor: '#9f35f1',
          minWidth: '100px',
          fontFamily: 'Farro',
          fontSize: 'small',
          border: '1px solid orange',
          borderRadius: '0',
          '&:hover': {
            backgroundColor: '#7b26c9',
          }
          }}> Top 7 Memes </Button>
      <div style={{marginTop: '30px'}}>
        {displayContent}
      </div>
    </div>
  );
};

Ranking.propTypes = {
  likeMeme: PropTypes.func,
  unlikeMeme: PropTypes.func,
  dislikeMeme: PropTypes.func,
  undislikeMeme: PropTypes.func,
};

export default Ranking;
