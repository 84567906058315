// Importing required libraries, modules, and components
import React from "react";
import { Link } from "react-router-dom";
import ReactModal from 'react-modal';
//Local Components and Images
import Ad from "./Ad"
import MoreInfo from './MoreInfo';
import logo from '../../images/twitter-icon.png';
//MUI
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import { Avatar, useMediaQuery, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import LogoutIcon from '@mui/icons-material/Logout';
import HomeIcon from '@mui/icons-material/Home';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
//Redux
import { connect, useDispatch } from 'react-redux';
import store from '../../redux/store';
import { logoutUser } from '../../redux/actions/userActions';
import { setRandomMeme } from "../../redux/actions/dataActions";
import '../../App.css';

ReactModal.setAppElement('#root');

function OverlaySidebar({ authenticated, user,  isSidebarVisible, setIsSidebarVisible  }) {
    const isSmallScreen = useMediaQuery('(max-width:768px)');
    const dispatch = useDispatch();
    const handleLogout = () => {
    store.dispatch(logoutUser());
  };

  if (isSmallScreen) {
    return (
      <Toolbar className={`overlay-sidebar ${!isSidebarVisible ? 'hide-sidebar' : ''}`}>
        <div className='overlay-sidebar'>
            {authenticated  ? (
            <div>
                <Avatar 
                component={Link} 
                to={"/profile/" + user.credentials.handle}
                alt={user.credentials.handle}
                src={user.credentials.imageUrl}
                sx={{
                    border: '3px solid orange',
                    height: '80px',
                    width: '80px',
                    margin: '0 auto 10px auto ',
                    
                    '&:hover': {
                    backgroundColor: '#7b26c9',
                    }
                    }}
                    onClick={() => setIsSidebarVisible(!isSidebarVisible)}
                    >{user.credentials.handle}
                </Avatar>
                <Typography 
                component={Link} 
                to={"/profile/" + user.credentials.handle} 
                sx={{ color: 'white', fontWeight: 'bold', textDecoration: 'none', '&:hover': {
                    color: 'orange'
                }}}
                >
                {user.credentials.handle}
                </Typography>
                <br />
                <IconButton component={Link} to="/notifications" onClick={() => setIsSidebarVisible(!isSidebarVisible)}>
                <NotificationsActiveIcon 
                sx={{
                    color:"white", 
                    fontSize:"large",
                    '&:hover': {
                    color: 'orange',
                    }}} />
                </IconButton>
                <IconButton component={Link} to="/favorites" onClick={() => setIsSidebarVisible(!isSidebarVisible)}>
                <FolderSpecialIcon 
                    sx={{
                    color:"white", 
                    fontSize:"large",
                    '&:hover': {
                        color: 'orange',
                    }}} />
                </IconButton>
                <IconButton component={Link} to="/upload" onClick={() => setIsSidebarVisible(!isSidebarVisible)} >
                    <CloudUploadIcon
                    sx={{
                        color:"white", 
                        fontSize:"large",
                        '&:hover': {
                        color: 'orange',
                        }
                    }} />
                </IconButton>
                <IconButton onClick={handleLogout}>
                    <LogoutIcon type="button" 
                    sx={{
                        color:"white", 
                        fontSize:"large",
                        '&:hover': {
                        color: 'orange',
                        }
                    }} 
                    />
                    </IconButton>
            </div>
            ) : (
            <Button 
                component={Link} 
                to="/login"
                className="button"
                variant="contained"
                sx={{
                backgroundColor: '#9f35f1',
                minWidth: '200px',
                fontFamily: 'Farro',
                fontSize: 'large',
                textTransform: 'none',
                border: '3px solid orange',
                borderRadius: '10px',
                '&:hover': {
                    backgroundColor: '#7b26c9',
                }
                }}
                onClick={() => setIsSidebarVisible(!isSidebarVisible)}>
                Login
            </Button>
            )}
            <hr className="overlay-divider" />
            <Typography sx={{
                fontFamily: 'Farro',
                fontSize: 'large',
            }}>Navigate</Typography>
            <div>
            <IconButton component={Link} to="/latest" onClick={() => setIsSidebarVisible(!isSidebarVisible)}> 
                <HomeIcon type="button" 
                sx={{
                    color:"white", 
                    fontSize:"x-large",
                    '&:hover': {
                    color: 'orange',
                    }
                }} 
                />
            </IconButton>
            <IconButton component={Link} to="/random" 
                onClick={() => {
                    setIsSidebarVisible(!isSidebarVisible);
                    dispatch(setRandomMeme());
                }}>
                <ShuffleIcon type="button" 
                sx={{
                    color:"white", 
                    fontSize:"x-large",
                    '&:hover': {
                    color: 'orange',
                    }
                }} 
                />
            </IconButton>
            <IconButton component={Link} to="/ranking" onClick={() => setIsSidebarVisible(!isSidebarVisible)}>
                <EmojiEventsIcon type="button" 
                    sx={{
                        color:"white", 
                        fontSize:"x-large",
                        '&:hover': {
                        color: 'orange',
                        }
                    }} 
                    />
            </IconButton>
            </div>
            <hr className="overlay-divider" />
            <MoreInfo />
            <hr className="overlay-divider" />
            <footer className="App-footer">
            <a href="https://twitter.com/snek_pics" target="_blank" rel="noreferrer">
                <img src={logo} alt="Logo" className="logo" />
            </a><br />
            <a className='whitepaper' href='https://snekpics.gitbook.io/whitepaper/' target="_blank" rel="noreferrer">📝Whitepaper📝</a>
            <p className="footer">© 2023 SNEKPICS</p>
            </footer>
            <Ad />
            </div>
      </Toolbar>
    );
  } else {
    return null;
  }

}

const mapStateToProps = (state) => ({
  authenticated: state.user.authenticated,
  user: state.user
});

export default connect(mapStateToProps)(OverlaySidebar);