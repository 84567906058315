import {
  SET_USER,
  SET_AUTHENTICATED,
  SET_UNAUTHENTICATED,
  LOADING_USER,
  LIKE_MEME,
  UNLIKE_MEME,
  DISLIKE_MEME,
  UNDISLIKE_MEME,
  MARK_NOTIFICATIONS_READ
} from '../types';

const initialState = {
  authenticated: false,
  loading: false,
  credentials: {},
  likes: [],
  notifications: []
};

function userReducer(state = initialState, action) {
  switch (action.type) {
    case SET_AUTHENTICATED:
      return {
        ...state,
        authenticated: true
      };
    case SET_UNAUTHENTICATED:
      return initialState;
    case SET_USER:
      return {
        authenticated: true,
        loading: false,
        ...action.payload
      };
    case LOADING_USER:
      return {
        ...state,
        loading: true
      };
    case LIKE_MEME:
      const newDislikes = state.dislikes.filter(
        (dislike) => dislike.memeId !== action.payload.memeId
      );
      return {
        ...state,
        likes: [
          ...state.likes,
          {
            userHandle: state.credentials.handle,
            memeId: action.payload.memeId
          }
        ],
        dislikes: newDislikes, 
      };
    case UNLIKE_MEME:
      return {
        ...state,
        likes: state.likes.filter(
          (like) => like.memeId !== action.payload.memeId
        )
      };
    case DISLIKE_MEME:
      const newLikes = state.likes.filter(
        (like) => like.memeId !== action.payload.memeId
      );
      return {
        ...state,
        dislikes: [
          ...state.dislikes,
          {
            userHandle: state.credentials.handle,
            memeId: action.payload.memeId
          }
        ],
        likes: newLikes, 
      };
    case UNDISLIKE_MEME: 
      return {
        ...state,
        dislikes: state.dislikes.filter(
          (dislike) => dislike.memeId !== action.payload.memeId
        )
      };
    case MARK_NOTIFICATIONS_READ:
      state.notifications.forEach((not) => (not.read = true));
      return {
        ...state
      };
    default:
      return state;
  }
}

export default userReducer;
