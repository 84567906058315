import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
// import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LinkIcon from '@mui/icons-material/Link';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { Avatar } from '@mui/material';

const StaticProfile = (props) => {
  const {
    profile: { handle, createdAt, imageUrl, bio, website, location },
  } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: '100%',
        background: 'linear-gradient(to top, #bc41dc, #02a2e6)',
        border: '2px solid orange',
        borderRadius: '20px',
        padding: '20px',
        margin: '0 auto 50px auto',
      }}
    >
      <Avatar
        sx={{
          width: 150,
          height: 150,
          border: '3px solid orange',
          backgroundColor: 'white',
          marginBottom: '20px',
        }}
        src={imageUrl}
        alt="profile image"
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          fontFamily: 'Farro',
          fontSize: 'small',
          maxWidth: '360px',
          color: 'white',
          margin: '0 auto',
          alignItems: 'center',
        }}
      >
        <Typography component="div" variant="h5" sx={{ marginTop: '10px', fontWeight: 'bold' }}>
          {handle}
        </Typography>
        <Typography component="div" variant="p" sx={{ marginTop: '10px', marginBottom: '20px', fontSize: 'medium' }}>
          {bio}
        </Typography>
        {location && (
          <Typography component="div" variant="p" sx={{ display: 'flex'}}>
            <LocationOnIcon color="primary" fontSize="small" sx={{ marginRight: '5px' }} />
            {location}
          </Typography>
        )}
        {website && (
          <Typography component="div" variant="p" sx={{ display: 'flex'}}>
            <LinkIcon color="primary" fontSize="small" sx={{ marginRight: '5px' }} />
            <a
              href={website}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                zIndex: 1,
                textDecoration: 'none',
                color: 'white',
                transition: 'color 0.3s',
              }}
              onMouseEnter={(e) => (e.target.style.color = 'orange')}
              onMouseLeave={(e) => (e.target.style.color = 'white')}
            >
              {' '}
              {website}
            </a>
          </Typography>
        )}
        <Typography component="div" variant="p" sx={{ display: 'flex'}}>
          <CalendarTodayIcon color="primary" fontSize="small" sx={{ marginRight: '5px' }} />
          <span>Joined {dayjs(createdAt).format('MMM YYYY')}</span>
        </Typography>
      </Box>
    </Box>
  );
};

StaticProfile.propTypes = {
  profile: PropTypes.object.isRequired,
};

export default StaticProfile;
