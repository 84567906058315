import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Avatar from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import JoyButton from '@mui/joy/Button';
import SvgIcon from '@mui/joy/SvgIcon';
import { styled } from '@mui/joy';

import { connect } from 'react-redux';
import { postMeme, clearErrors } from '../redux/actions/dataActions';

const VisuallyHiddenInput = styled('input')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

const initialState = {
    body: '',
    tags: '',
    image: null,
    imageName: '',
    errors: {},
    success: {}
  };

class Upload extends Component {
  state = { ...initialState };

  componentDidUpdate(prevProps) {
    if (
      prevProps.UI.loading === true &&
      this.props.UI.loading === false &&
      !this.props.UI.errors
    ) {
        this.setState({
            ...initialState,
            success: { general: "Image successfully Uploaded!"}
          });
    } 
    
    if (
      prevProps.UI.loading === true &&
      this.props.UI.loading === false &&
      this.props.UI.errors
    ) {
        console.log(this.props.UI.errors)
        this.setState({
            ...initialState,
            success: {general: 'Error occured, please try again.'}
          });
    }
  }
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleImageChange = (event) => {
    const imageFile = event.target.files[0];
    if (imageFile) {
      const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
      if (allowedTypes.includes(imageFile.type) && imageFile.size <= 7 * 1024 * 1024) {
        this.setState({ image: imageFile, imageName: imageFile.name, errors: {} });
      } else {
        this.setState({
          image: null,
          imageName: '',
          errors: { image: 'Invalid file type or size (max 7MB)' }
        });
      }
    }
  };  
  handleSubmit = async (event) => {
    event.preventDefault();
  
    const newMeme = new FormData();
    newMeme.append('body', this.state.body);
    newMeme.append('tags', this.state.tags);
    newMeme.append('image', this.state.image);

    await this.props.postMeme(newMeme)

    this.setState({
        body: null,
        tags: null,
    });
  };
  
  render() {
    const { errors, success } = this.state;
    const {
      UI: { loading }
    } = this.props;
    return (
        <Container component="main" maxWidth="xs" sx={{
            backgroundColor:'white',
            paddingTop: '1px',
            paddingBottom: '50px',
            borderRadius: '5px',
            border: '2px solid orange'
          }}>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <AddAPhotoIcon sx={{color: 'black'}} />
          </Avatar>
          <Typography component="h1" variant="h5">
            Upload Form
          </Typography>
          <Box component="form" autoComplete="off" noValidate onSubmit={this.handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  name="body"
                  label="Meme Title"
                  placeholder="Make a good title!"
                  error={errors.body ? true : false}
                  helperText={errors.body}
                  onChange={this.handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  name="tags"
                  label="Tags"
                  placeholder="Separate with a Comma (eg. SNEK, PICS)"
                  error={errors.tags ? true : false}
                  helperText={errors.tags} 
                  onChange={this.handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <JoyButton
                    component="label"
                    role={undefined}
                    tabIndex={-1}
                    variant="outlined"
                    color="neutral"
                    startDecorator={
                    <SvgIcon>
                        <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        >
                         <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                          />
                        </svg>
                    </SvgIcon>
                    }
                >
                    Upload a file
                    <VisuallyHiddenInput type="file" onChange={this.handleImageChange} />
                </JoyButton>
                {this.state.imageName && <p style={{fontSize: 'x-small'}}>{this.state.imageName}</p>}
                {errors.image && (
              <Typography variant="body2">
                {errors.image}
              </Typography>
            )}
                </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={loading || !this.state.body || !this.state.tags || !this.state.imageName}
              sx={{ mt: 3, mb: 2 }}
            >
              Post Meme
              {loading && (
                  <CircularProgress
                    size={30}
                  />
                )}
            </Button>
            {success.general && (
              <Typography variant="body2">
                {success.general}
              </Typography>
            )}
            <Grid container justifyContent="flex-end">
            </Grid>
          </Box>
        </Box>
      </Container>
    );
  }
}

Upload.propTypes = {
  postMeme: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  UI: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  UI: state.UI
});

export default connect(
  mapStateToProps,
  { postMeme, clearErrors }
)(Upload);