import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

const Trending = () => {
  const [trendingTags, setTrendingTags] = useState([]);

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const res = await axios.get('/tags');
        let tagsData = res.data;

        const tagsFrequency = {};
        tagsData.forEach(doc => {
          doc.tags.forEach(tag => {
            const normalizedTag = tag.toLowerCase().replace(/^#/, '').trim();
            if (tagsFrequency[normalizedTag]) {
              tagsFrequency[normalizedTag]++;
            } else {
              tagsFrequency[normalizedTag] = 1;
            }
          });
        });

        const sortableTags = Object.keys(tagsFrequency).map(tag => ({
          tag,
          count: tagsFrequency[tag],
        }));
        sortableTags.sort((a, b) => b.count - a.count);

        setTrendingTags(sortableTags.slice(0, 7));
      } catch (error) {
        console.error("Error fetching tags:", error);
      }
    };

    fetchTags();
  }, []);

  return (
    <div>
      <h3 style={{color: 'white', marginBottom: '10px'}}>Trending Tags</h3>
      {trendingTags.map(({ tag }) => (
        <Button 
          key={tag}
          component={Link} 
          to={`/search?tag=${tag}`}
          variant="contained"
          sx={{
            backgroundColor: '#bc41dc',
            color: 'white',
            padding: '5px 10px',
            margin: '5px',
            border: 'none',
            borderRadius: '25px',
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: '#9f35f1',
                color: 'orange',
            }
          }}
        >
          {`${tag}`}
        </Button>
      ))}
    </div>
  );
};

export default Trending;