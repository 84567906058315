import React, { useState, useEffect } from 'react';
import '../../styles/Ad.css';

import ad1 from '../../images/Ad/SNEKPICS-SMALL.png'
import ad2 from '../../images/Ad/SNEK-WEEK-SMALL.png'
import ad3 from '../../images/Ad/Checked-NFT-small.jpg'

const ads = [
  { content: 'Follow us on Twitter', img: ad1,  link: 'https://twitter.com/SNEK_PICS/status/1686849707082121222' },
  { content: 'Click to Learn More', img: ad2, link: 'https://snekweek.com/' },
  { content: 'Click to Learn More', img: ad3, link: 'https://checkednft.xyz/' },
  // Add more ads
];

function Ad() {
  const [currentAdIndex, setCurrentAdIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentAdIndex((prevIndex) => (prevIndex + 1) % ads.length);
    }, 7000); 

    return () => clearInterval(interval); 
  }, []);

  return (
    <a className="overlay-ad-post" href={ads[currentAdIndex].link} target="_blank" rel="noreferrer" title={ads[currentAdIndex].content}>
      <img src={ads[currentAdIndex].img} alt='ad' />
    </a>
  );
}

export default Ad;
