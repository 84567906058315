import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import CommentForm from "../component/CommentForm";
//MUI
//import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import GradeIcon from '@mui/icons-material/Grade';
import ShareIcon from '@mui/icons-material/Share';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AddCommentIcon from '@mui/icons-material/AddComment';
import Typography from '@mui/material/Typography';

const MemeCard = ({ meme, user, likeMeme, unlikeMeme, dislikeMeme, undislikeMeme }) => {
    dayjs.extend(relativeTime);
    const [isLiked, setIsLiked] = useState(false);
    const [isDisliked, setIsDisliked] = useState(false);
    const [showCommentForm, setShowCommentForm] = useState(false);
  
    useEffect(() => {
      if (
        user.likes &&
        user.likes.find(
          (like) => like.memeId === meme.memeId
        )
      ) {
        setIsLiked(true);
        setIsDisliked(false);
      } else if (
        user.dislikes &&
        user.dislikes.find(
          (dislike) => dislike.memeId === meme.memeId
        )
      ) {
        setIsDisliked(true);
        setIsLiked(false);
      } else {
        setIsLiked(false);
        setIsDisliked(false);
      }
    }, [user, meme]);
  
    const handleLike = () => {
      if (!user.authenticated) {
        window.alert("You need to be logged in to like a meme.");
        return;
      }
      
      if (isLiked) {
        unlikeMeme(meme.memeId);
      } else {
        likeMeme(meme.memeId);
      }
    }; 
    const handleDislike = () => {
      if (!user.authenticated) {
        window.alert("You need to be logged in to dislike a meme.");
        return;
      }
      
      if (isDisliked) {
        undislikeMeme(meme.memeId);
      } else {
        dislikeMeme(meme.memeId);
      }
    };

  const calculateLikesRatio = (likeCount, dislikeCount) => {
    if (dislikeCount === 0) {
      if (likeCount === 0) {
        return 0;
      } else {
        return 100;
      }
    } else if (likeCount === 0) {
        return -dislikeCount; 
    } else {
      const ratio = Math.round((likeCount / (likeCount + dislikeCount)) * 100);
        return ratio;
    }  
  };

  const handleDownloadClick = () => {
    const link = document.createElement('a');
    link.href = meme.memeImageUrl;
    link.download = `memes_${meme.memeImageName}`;
    link.target = '_blank';
    link.click();
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(`https://snek.pics/meme/${meme.memeId}`);
    } catch (error) {
      console.error('Failed to copy text: ', error);
    }
  };

  const toggleCommentForm = () => {
    setShowCommentForm(!showCommentForm);
  };

  const handleCommentSubmitted = () => {
    setShowCommentForm(false);
  };

  return (
    <Card sx={{ 
        maxWidth: '500px',
        marginRight: 'auto',
        marginLeft: 'auto', 
        marginBottom: '50px',
        outline: '3px solid #353535',
        textAlign: 'left'
        }}>
    <CardHeader
      sx = {{
        backgroundColor: '#833fb6',
        fontFamily: 'Farro',
        maxHeight: '100px'
      }}
      disableTypography={true}
      /* avatar={
        <Avatar
            component={ Link } 
            to={"/user/"+ meme.userHandle}
            sx={{
            width: 42,
            height: 42,
            border: '2px solid orange'
            }}
            src={meme.userImage}
            alt="profile image"
        />
      } */
      action={
        <IconButton 
        sx={{
            width: '45x',
            height: '35px'
        }}>
          <GradeIcon sx={{
            width: '35px',
            height: 'auto'
        }}/>
        </IconButton>
      }
      title={<Typography
        component={ Link } 
        to={"/meme/"+ meme.memeId}
        sx={{
            display: 'flex',
            fontFamily: 'Farro',
            fontSize: { xs: 'medium', md: 'x-large', lg: 'x-large' },
            color: 'white',
            fontWeight: 'bolder',
            textDecoration: 'none'
        }}>{meme.body}</Typography>}
      subheader={<Typography
        component={ Link } 
        to={"/user/"+ meme.userHandle}
        ariant="body2" 
        color="silver"
        sx={{
            fontSize: { xs: 'x-small', md: 'small', lg: 'small' },
            fontFamily: 'Farro',
            textDecoration: 'none'
        }}>
      {meme.userHandle} • {dayjs(meme.createdAt).fromNow()}
      </Typography>}
    />
        
    <CardMedia
      component="img"
      src={meme.memeImageUrl}
      alt="meme"
      sx={{
        maxHeight: 'auto',
        width: '100%',
        marginRight: '50px'
      }}
    />
    <CardActions 
      sx={{
      backgroundColor: '#833fb6',
      color: '#02a2e6',
      justifyContent: 'space-evenly',
      display: 'flex'
      }}
    >
      <IconButton onClick={toggleCommentForm}>
        <AddCommentIcon sx={{color: 'whitesmoke'}} />
        <Typography 
          sx={{
            marginLeft: '5px',
            fontSize: 'small',
            fontFamily: 'Farro',
            color: 'whitesmoke'
          }}>{meme.commentCount}
        </Typography>
      </IconButton>
      <IconButton onClick={handleLike}>
              <ThumbUpAltIcon sx={{ color: isLiked ? '#02a2e6' : 'orange' }} />
              <Typography 
              sx={{
                  marginLeft: '5px',
                  fontSize: 'small',
                  fontFamily: 'Farro',
                  color: 'whitesmoke'
              }}>{meme.likeCount}</Typography>
            </IconButton>
      <IconButton onClick={handleDislike}>
        <ThumbDownIcon sx={{ color: isDisliked ? '#800020' : 'gray' }} />
              <Typography 
              sx={{
                  marginLeft: '5px',
                  fontSize: 'small',
                  fontFamily: 'Farro',
                  color: 'whitesmoke'
              }}>{meme.dislikeCount}</Typography>
      </IconButton>
      <Typography>{calculateLikesRatio(meme.likeCount, meme.dislikeCount)}%</Typography>
      <IconButton aria-label="download" onClick={handleDownloadClick}>
        <FileDownloadIcon />
      </IconButton>
      <IconButton aria-label="share" onClick={copyToClipboard}>
        <ShareIcon color="primary" />
      </IconButton>
    </CardActions>
      <div className="content-tags">
        {meme.tags.map((tag, tagIndex) => (
          // Using the Link component to make each tag clickable
          <Link 
            to={`/search?tag=${tag}`}
            style={{ textDecoration: 'none' }} // Optional: Style as needed
            key={tagIndex}
          >
            <button className="tag-button">{tag}</button>
          </Link>
        ))}
      </div>
      {showCommentForm && user.authenticated && (
        <CommentForm memeId={meme.memeId} onCommentSubmitted={handleCommentSubmitted} />
      )}
  </Card>
  );
};

export default MemeCard;
