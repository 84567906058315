// Import modules
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
//Meme Component
import MemeCard from "./MemeCard";
//MUI
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
//Redux
import { useDispatch, useSelector } from "react-redux";
import { getMemes, setRandomMeme, likeMeme, unlikeMeme, dislikeMeme, undislikeMeme } from '../redux/actions/dataActions';

const Random = () => {
  const dispatch = useDispatch();
  const randomMeme = useSelector((state) => state.data.randomMeme);
  const loading = useSelector((state) => state.UI.loading);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(getMemes());
  }, [dispatch]); 

  const handleLikeMeme = (memeId) => {
    dispatch(likeMeme(memeId));
  };

  const handleUnlikeMeme = (memeId) => {
    dispatch(unlikeMeme(memeId));
  };

  const handleDislikeMeme = (memeId) => {
    dispatch(dislikeMeme(memeId));
  };

  const handleUndislikeMeme = (memeId) => {
    dispatch(undislikeMeme(memeId));
  };

  let randomMemeMarkup;
  if (loading) {
    randomMemeMarkup = <CircularProgress />;
  } else if (randomMeme) {
    randomMemeMarkup = (
      <MemeCard 
        key={randomMeme.memeId} 
        meme={randomMeme} 
        user={user}
        likeMeme={() => handleLikeMeme(randomMeme.memeId)} 
        unlikeMeme={() => handleUnlikeMeme(randomMeme.memeId)}
        dislikeMeme={() => handleDislikeMeme(randomMeme.memeId)} 
        undislikeMeme={() => handleUndislikeMeme(randomMeme.memeId)}
        />
    );
  } else {
    randomMemeMarkup = <Button 
      component={Link} to="/random" 
      onClick={() => dispatch(setRandomMeme())}
      className="button"
      variant="contained"
      sx={{ 
        backgroundColor: '#9f35f1',
        minWidth: '200px',
        fontFamily: 'Farro',
        fontSize: 'large',
        textTransform: 'none',
        border: '3px solid orange',
        borderRadius: '10px',
        marginBottom: '10px',
        '&:hover': {
          backgroundColor: '#9f35f1',
          color: 'orange',
        }
        }}>Generate Meme</Button>;
  }

  return (
    <div>
      {randomMemeMarkup}
    </div>
  );
};

Random.propTypes = {
  likeMeme: PropTypes.func,
  unlikeMeme: PropTypes.func,
  dislikeMeme: PropTypes.func,
  undislikeMeme: PropTypes.func,
};

export default Random;