import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

const Form = ({ handleSubmit, handleImageChange, image, handleImageUpload  }) => {
  const [bio, setBio] = useState('');
  const [website, setWebsite] = useState('');
  const [location, setLocation] = useState('');

  const submit = (event) => {
    event.preventDefault();
    handleSubmit({ bio, website, location, image });

    if (image) {
        handleImageUpload();  
      }
  };

  return (
      <form style={{
          backgroundColor: 'white',
          border: '3px solid orange',
          borderRadius: '10px'
          }}>
          <TextField
              name="bio"
              type="text"
              label="Bio"
              multiline
              rows="3"
              placeholder="A short bio about yourself"
              value={bio}
              onChange={(e) => setBio(e.target.value)}
              sx={{marginTop: '10px'}}
              fullWidth
          />
          <TextField
              name="website"
              type="text"
              label="Website"
              placeholder="Your personal/professional website"
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
              sx={{marginTop: '10px'}}
              fullWidth
          />
          <TextField
              name="location"
              type="text"
              label="Location"
              placeholder="Where you live"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              sx={{marginTop: '10px'}}
              fullWidth
              />
              <input
              type="file"
              id="imageInput"
              hidden="hidden"
              onChange={handleImageChange} 
          />
          <Button 
              tip="Edit profile picture" 
              onClick={() => document.getElementById('imageInput').click()}
              >
              <AddPhotoAlternateIcon color="primary" />
          </Button>
          {image && (
          <img 
              src={URL.createObjectURL(image)} 
              alt="Preview" 
              style={{ height: '70px', width: '70px', marginTop: '20px', marginBottom: '-10px' }} 
          />
          )}<br />
        <Button onClick={submit} sx={{backgroundColor: 'purple', color: 'white', borderRadius: '10px', margin: '10px'}}>
          Submit
        </Button>
      </form>
  );
};

export default Form;
