// Importing required libraries, modules, and components
import React, {useEffect} from 'react';
import { Link, useNavigate } from 'react-router-dom';
//MUI
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import useMediaQuery from '@mui/material/useMediaQuery';
import CardMedia from '@mui/material/CardMedia';
//Images
import logo from '../images/snekpics.png'

// Styled Components
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

// Navbar Functional Component
function Navbar({ navQuery, onSearchChange, isSidebarVisible, setIsSidebarVisible  }) {
  // Media query to adjust UI based on screen size
  const isSmallScreen = useMediaQuery('(max-width:768px)');

  const navigate = useNavigate();

  const handleSearchChange = (value) => {
    onSearchChange(value);

    if (value.trim()) {
      navigate("/search");
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    const overlaySidebar = document.querySelector('body');
    
    if (isSidebarVisible && isSmallScreen) {
      overlaySidebar.style.overflow = 'hidden';
    } else {
      overlaySidebar.style.overflow = 'auto';
    }

    return () => {
      overlaySidebar.style.overflow = 'auto';
    };
  }, [isSidebarVisible, isSmallScreen]);

  // JSX Return
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar className='header'>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              type="text"
              placeholder="Search..."
              inputProps={{ 'aria-label': 'search' }}
              value={navQuery}
              onChange={(e) => handleSearchChange(e.target.value)}
            />
          </Search>
          <Box 
            component={Link} 
            to='/'
            sx={{ 
              flexGrow: 1, 
              display: { xs: 'none', sm: 'block' },
              objectFit: 'contain',
            }}>
            <CardMedia
            component="img"
            height="100"
            image={logo}
            alt="SNEKPICS"
            sx={{
              objectFit: 'contain',
            }}
            >
            </CardMedia>
          </Box>
          {isSmallScreen && (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={{ mr: 2, marginLeft: '15px' }}
              onClick={() => setIsSidebarVisible(!isSidebarVisible)}
            >
              <MenuIcon fontSize='large'/>
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default Navbar;