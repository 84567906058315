// Import required modules and components
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css'; 
import App from './App'; 
import store from "./redux/store"; 
import { Provider } from "react-redux"; 

// Create a root for concurrent rendering with React
const root = ReactDOM.createRoot(document.getElementById('root'));

// Render the application into the root element
root.render(
  // Wrap the App component with Redux Provider to make the Redux store available
  <Provider store={store}>
    <App />
  </Provider>
);
