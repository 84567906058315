// User reducer types
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_UNAUTHENTICATED = 'SET_UNAUTHENTICATED';
export const SET_USER = 'SET_USER';
export const LOADING_USER = 'LOADING_USER';
export const MARK_NOTIFICATIONS_READ = 'MARK_NOTIFICATIONS_READ';
// UI reducer types
export const SET_ERRORS = 'SET_ERRORS';
export const LOADING_UI = 'LOADING_UI';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const LOADING_DATA = 'LOADING_DATA';
export const STOP_LOADING_UI = 'STOP_LOADING_UI';
// Data reducer types
export const SET_MEMES = 'SET_MEMES';
export const SET_MEME = 'SET_MEME';
export const LIKE_MEME = 'LIKE_MEME';
export const UNLIKE_MEME = 'UNLIKE_MEME';
export const DISLIKE_MEME = 'DISLIKE_MEME';
export const UNDISLIKE_MEME = 'UNDISLIKE_MEME';
export const DELETE_MEME = 'DELETE_MEME';
export const POST_MEME = 'POST_MEME';
export const SUBMIT_COMMENT = 'SUBMIT_COMMENT';
export const SET_RANDOM_MEME = 'SET_RANDOM_MEME';
export const GET_USER_PROFILE_DATA = 'GET_USER_PROFILE_DATA';