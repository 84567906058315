import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
//Components
import MemeCard from '../component/MemeCard';
//MUI
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
//Redux
import { useSelector, useDispatch } from 'react-redux';
import { getMemes, likeMeme, unlikeMeme, dislikeMeme, undislikeMeme } from "../redux/actions/dataActions";

function Search({ navQuery }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const tagQuery = query.get('tag');
  const user = useSelector((state) => state.user);
  const [filteredMemes, setFilteredMemes] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchBy, setSearchBy] = useState('tags'); 
  const [searchQuery, setSearchQuery] = useState('');
  const [memes, setMemes] = useState([]); 
  
  const redMemes = useSelector(state => state.data.memes);

  useEffect(() => {
    if (redMemes.handle !== undefined) {
      axios.get('/memes')
        .then((res) => {
          setMemes(res.data); 
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setMemes(redMemes);  
    }
  }, [redMemes]);

  useEffect(() => {
    if (!memes || !memes.length) {
      dispatch(getMemes());
    }
  }, [memes, dispatch]);

  const uniqueUsers = useMemo(() => {
    const userSet = new Set();
    if (Array.isArray(memes)) {
      memes.forEach(meme => {
        if (meme && meme.userHandle) {
          userSet.add(meme.userHandle);
        }
      });
    }
    return Array.from(userSet);
  }, [memes]);

  useEffect(() => {
    const lowercaseQuery = searchQuery.toLowerCase();
    if (Array.isArray(memes)) {
      if (searchBy === 'tags') {
        setFilteredMemes(memes.filter(meme => meme.tags.some(tag => tag.toLowerCase().includes(lowercaseQuery))));
        setFilteredUsers([]);
      } else if (searchBy === 'tag') {
        setFilteredMemes(memes.filter(meme => meme.tags.some(tag => tag.toLowerCase() === lowercaseQuery)));
        setFilteredUsers([]);
      } else if (searchBy === 'title') {
        setFilteredMemes(memes.filter(meme => meme.body.toLowerCase().includes(lowercaseQuery)));
        setFilteredUsers([]);
      } else {
        setFilteredMemes([]);
        setFilteredUsers(uniqueUsers.filter(user => user.toLowerCase().includes(lowercaseQuery)));
      }
    }
  }, [searchQuery, searchBy, memes, uniqueUsers]);

  useEffect(() => {
    if (tagQuery) {
      setSearchQuery(tagQuery);
      setSearchBy('tag');
    } else if (navQuery) {
      setSearchQuery(navQuery)
      setSearchBy('tags');
    } else {
      setSearchQuery('')
    }
    
  }, [tagQuery, navQuery]);

  const handleLikeMeme = (memeId) => {
    dispatch(likeMeme(memeId));
  };

  const handleUnlikeMeme = (memeId) => {
    dispatch(unlikeMeme(memeId));
  };

  const handleDislikeMeme = (memeId) => {
    dispatch(dislikeMeme(memeId));
  };

  const handleUndislikeMeme = (memeId) => {
    dispatch(undislikeMeme(memeId));
  };

  return (
    <div>
      <Button 
        className="button"
        variant="contained"
        onClick={() => setSearchBy('tags')}
        sx={{
          backgroundColor: '#9f35f1',
          minWidth: '100px',
          fontFamily: 'Farro',
          fontSize: 'small',
          border: '1px solid orange',
          borderRadius: '0',
          '&:hover': {
            backgroundColor: '#7b26c9',
          }
          }}> Search by Tags </Button>
      <Button 
        className="button"
        variant="contained"
        onClick={() => setSearchBy('title')}
        sx={{
          backgroundColor: '#9f35f1',
          minWidth: '100px',
          fontFamily: 'Farro',
          fontSize: 'small',
          border: '1px solid orange',
          borderRadius: '0',
          '&:hover': {
            backgroundColor: '#7b26c9',
          }
          }}> Search by Title </Button>
      <Button 
        className="button"
        variant="contained"
        onClick={() => setSearchBy('user')}
        sx={{
          backgroundColor: '#9f35f1',
          minWidth: '100px',
          fontFamily: 'Farro',
          fontSize: 'small',
          border: '1px solid orange',
          borderRadius: '0',
          '&:hover': {
            backgroundColor: '#7b26c9',
          }
          }}> Search by User </Button>

      <div style={{marginTop: '30px'}}>
        { searchBy !== 'user' }
        {filteredMemes.map(meme => 
          <MemeCard 
            key={meme.memeId} 
            meme={meme}
            user={user}
            likeMeme={() => handleLikeMeme(meme.memeId)} 
            unlikeMeme={() => handleUnlikeMeme(meme.memeId)}
            dislikeMeme={() => handleDislikeMeme(meme.memeId)} 
            undislikeMeme={() => handleUndislikeMeme(meme.memeId)}
             />)}
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '5px'}}>
        { searchBy === 'user' }
        {filteredUsers.map(user => 
          <Typography key={user} component={ Link } to={"/user/"+ user}>
            {user} 
          </Typography>)}
      </div>
    </div>
  );
}

Search.propTypes = {
  likeMeme: PropTypes.func,
  unlikeMeme: PropTypes.func,
  dislikeMeme: PropTypes.func,
  undislikeMeme: PropTypes.func,
};

export default Search;
