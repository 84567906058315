import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { CircularProgress } from '@mui/material';

import { connect } from 'react-redux';
import { signupUser } from '../redux/actions/userActions';


class signup extends Component {
    constructor() {
        super();
        this.state = {
          email: '',
          password: '',
          confirmPassword: '',
          handle: '',
          errors: {}
        };
      }
      static getDerivedStateFromProps(props) {
        if (props.UI.errors !== null) {
          return { errors: props.UI.errors };
        } else return null
      }
      handleSubmit = (event) => {
        event.preventDefault();
        this.setState({
          loading: true
        });
        const newUserData = {
          email: this.state.email,
          password: this.state.password,
          confirmPassword: this.state.confirmPassword,
          handle: this.state.handle
        };
        this.props.signupUser(newUserData, this.props.history);
      };
      handleChange = (event) => {
        this.setState({
          [event.target.name]: event.target.value
        });
      };

  render() {
    const {
        UI: { loading }
      } = this.props;
      const { errors } = this.state;

    return (
        <Container component="main" maxWidth="xs" sx={{
            backgroundColor:'white',
            paddingTop: '1px',
            paddingBottom: '50px',
            borderRadius: '5px',
            border: '2px solid orange'
          }}>
        <CssBaseline />
        <Box
            sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            }}
        >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon sx={{color: 'black'}}/>
            </Avatar>
            <Typography component="h1" variant="h5">
            Sign up
            </Typography>
            <Box component="form" noValidate onSubmit={this.handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                <TextField
                    required
                    id="email"
                    name="email"
                    type="email"
                    label="Email"
                    helperText={errors.email}
                    error={errors.email ? true : false}
                    value={this.state.email}
                    onChange={this.handleChange}
                    fullWidth
                />
                </Grid>
                <Grid item xs={12}>
                <TextField
                    required
                    id="password"
                    name="password"
                    type="password"
                    label="Password"
                    helperText={errors.password}
                    error={errors.password ? true : false}
                    value={this.state.password}
                    onChange={this.handleChange}
                    fullWidth
                />
                </Grid>
                <Grid item xs={12}>
                <TextField
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    label="Confirm Password"
                    helperText={errors.confirmPassword}
                    error={errors.confirmPassword ? true : false}
                    value={this.state.confirmPassword}
                    onChange={this.handleChange}
                    fullWidth
                />
                </Grid>
                <Grid item xs={12}>
                <TextField
                    required
                    id="handle"
                    name="handle"
                    type="text"
                    label="Username"
                    helperText={errors.handle}
                    error={errors.handle ? true : false}
                    value={this.state.handle}
                    onChange={this.handleChange}
                    fullWidth
                />
                </Grid>
            </Grid>
            {errors.general && (
              <Typography variant="body2">
                {errors.general}
              </Typography>
            )}
            <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={loading}
                sx={{ mt: 3, mb: 2 }}
            >
                Sign Up
                {loading && (
                <CircularProgress size={30} />
              )}
            </Button>
            <Grid container justifyContent="center">
                <Grid item>
                <Link 
                  to="/login" 
                  variant="body2"
                  style={{
                    fontSize: 'medium',
                    fontFamily: 'Roboto',
                  }}>
                    Already have an account? Sign in
                </Link>
                </Grid>
            </Grid>
            </Box>
        </Box>
        </Container>
    );
    }
}

signup.propTypes = {
    user: PropTypes.object.isRequired,
    UI: PropTypes.object.isRequired,
    signupUser: PropTypes.func.isRequired
  };

  const mapStateToProps = (state) => ({
    user: state.user,
    UI: state.UI
  });
  
  export default connect(
    mapStateToProps,
    { signupUser }
  )(signup);