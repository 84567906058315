import React from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ChatIcon from '@mui/icons-material/Chat';
import { connect } from 'react-redux';
import { markNotificationsRead } from '../redux/actions/userActions'; 
import { Typography } from '@mui/material';

dayjs.extend(relativeTime);

const NotificationsPage = ({ notifications, markNotificationsRead }) => {

  const markAsRead = (notificationId) => {
    markNotificationsRead([notificationId]);
  };

  return (
    <div style={{maxWidth: '500px', background: 'white', borderRadius: '20px', border: '2px solid orange', padding: '30px 30px 30px 0', justifyContent: 'center', margin: '0 auto'}}>
        <Typography sx={{textDecoration: 'underline', fontFamily: 'Farro', fontWeight: 'bold', fontSize: { xs: 'large', md: 'x-large', lg: 'x-large' }}}>Notifications</Typography>
        <ul>
        {notifications.map((notification) => {
            const verb = notification.type === 'like' ? 'liked' : 'commented on';
            const time = dayjs(notification.createdAt).fromNow();
            const iconColor = notification.read ? 'primary' : 'red';
            const icon = notification.type === 'like' ? (
            <FavoriteIcon color={iconColor} sx={{ marginRight: '5px', fontSize: { xs: 'large', md: 'x-large', lg: 'x-large' } }} />
            ) : (
            <ChatIcon color={iconColor} sx={{ marginRight: '5px', fontSize: { xs: 'large', md: 'x-large', lg: 'x-large' }}} />
            );
        
            return (
            <li 
                key={notification.notificationId} 
                onClick={() => markAsRead(notification.notificationId)}
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}
            >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                {icon}
                <Typography sx={{ fontFamily: 'Farro', fontSize: { xs: 'x-small', sm: 'small', md: 'medium', lg: 'large' } }}>
                    <Link to={`/user/${notification.sender}`} style={{textDecoration: 'none'}}>{notification.sender}</Link> {verb} your
                    <Link to={`/meme/${notification.memeId}`} style={{textDecoration: 'none'}}> post</Link>
                </Typography>
                </div>
                <Typography sx={{ marginLeft: '10px', fontFamily: 'Farro', fontSize: { xs: 'x-small', sm: 'small', md: 'medium', lg: 'large' } }}>
                {time}
                </Typography>
            </li>
            );
        })}
        </ul>
    </div>
  );
};

const mapStateToProps = (state) => ({
  notifications: state.user.notifications,
});

export default connect(mapStateToProps, { markNotificationsRead })(NotificationsPage);
