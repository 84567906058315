import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
// MUI
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Avatar } from '@mui/material';


class Comments extends Component {
  render() {
    const { comments } = this.props;
    return (
      <Grid container sx={{
        maxWidth: '500px',
        marginRight: 'auto',
        marginLeft: 'auto', 
        marginTop: '-30px',
        marginBottom: '20px'
        }}>
        {comments.map((comment, index) => {
          const { body, createdAt, userImage, userHandle } = comment;
          return (
            <Grid item key={createdAt} sm={12} sx={{
              marginBottom: '30px',
              width: '100%',
              padding: '20px',
              backgroundColor:'white',
              borderRadius: '5px',
              border: '2px solid orange',
              textAlign: 'center',
              }}>
              <Grid container>
                <Avatar src={userImage} sx={{
                  height: { sm: 50, md: 60, lg: 90 },
                  width: { sm: 50, md: 60, lg: 90 },
                  marginRight: '20px'
                }} />
                <Grid item sm={9}>
                  <div>
                    <Typography
                      component={Link}
                      to={`/user/${userHandle}`}
                      color="primary"
                      sx={{
                        fontSize: 'medium',
                        fontWeight: 'bold',
                        textDecoration: 'none'
                      }}
                    >
                      {userHandle}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {dayjs(createdAt).format('h:mm a, MMMM DD YYYY')}
                    </Typography>
                    <hr/>
                    <Typography variabnt="body1">{body}</Typography>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    );
  }
}

Comments.propTypes = {
  comments: PropTypes.array.isRequired
};

export default Comments;